.html-switch {
    position: relative;
    display: inline-block;
    background-color: #eeeeee;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15) inset;
    color: #333333;
    overflow: hidden;
    line-height: 1.2em;
    margin-bottom: 10px;

    input {
        display: inline-block;
        position: absolute;
        z-index: 1;
        visibility: hidden;
        & + div {
            position: absolute;
            left: 0;
            width: 50%;
            top: 0;
            bottom: 0;
            content: "";
            display: block;
            background: none;
            z-index: 0;
            transition: all 0.5s;
            box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15) inset;
        }
        &:checked + .left-switch {
            background: #5cb85c;
            left: 0;
        }
        & + .left-switch {
            left: 50%;
        }
        &:checked + .right-switch {
            background: #e27c79;
            left: 50%;
        }
        &:checked + div + label {
            color: #ffffff;
        }
    }
    label {
        position: relative;
        cursor: pointer;
        margin: 0;
        padding: 0.5em 1em;
        text-align: center;
        transition: color 0.2s ease-out 0s;
        display: inline-block;
    }
}

#ajax-loader {

    $loader-double--color: $blue !default;
    $loader-double--color-external: $orange-main !default;
    $loader-double--size: 48px !default;
    $loader-double--border: 8px !default;

    $loader-double--size-external: $loader-double--size + ($loader-double--border * 2);

    &.is-active:after, &.is-active:before {
        display: block
    }

    @keyframes rotation {
        0% {
            transform: rotate(0)
        }
        to {
            transform: rotate(359deg)
        }
    }

    @keyframes blink {
        0% {
            opacity: .5
        }
        to {
            opacity: 1
        }
    }

    color: #fff;
    position: fixed;
    box-sizing: border-box;
    left: -9999px;
    top: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 999999;

    &:after,
    &:before {
        content: '';
        position: fixed;
        border-radius: 50%;
        border: solid $loader-double--border;
        animation: rotation 1s linear infinite;

        box-sizing: border-box;
        display: none
    }

    &:after {
        width: $loader-double--size;
        height: $loader-double--size;
        border-color: $loader-double--color;
        border-left-color: transparent;
        top: calc(50% - #{$loader-double--size / 2});
        left: calc(50% - #{$loader-double--size / 2});
    }

    &:before {
        width: $loader-double--size-external;
        height: $loader-double--size-external;
        border-color: $loader-double--color-external;
        border-right-color: transparent;
        animation-duration: 2s;
        top: calc(50% - #{($loader-double--size / 2) + $loader-double--border});
        left: calc(50% - #{($loader-double--size / 2) + $loader-double--border});
    }

    &.is-active {
        background-color: rgba(0, 0, 0, .85);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}