$orange-main: #e64735;
$blue: #255090;
$lightblue: lightskyblue;

$grey: #171717;
$whiteish: #fffef7;
$link-hover: #439473;

$green: #4caf50;

$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-width: 1200px;
$hd-width: 1600px;

@mixin below-phone {
    @media (max-width: #{$phone-width - 1px}) {
        @content;
    }
}

@mixin phone-strict {
    @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin phone {
    @media (min-width: #{$phone-width}) {
        @content;
    }
}

@mixin below-tablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet-strict {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin below-desktop {
    @media (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop-strict {
    @media (min-width: #{$desktop-width}) and (max-width: #{$large-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin below-large {
    @media (max-width: #{$large-width - 1px}) {
        @content;
    }
}

@mixin large-strict {
    @media (min-width: #{$large-width}) and (max-width: #{$large-width - 1px}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$large-width}) {
        @content;
    }
}

@mixin below-hd {
    @media (max-width: #{$hd-width - 1px}) {
        @content;
    }
}

@mixin hd-strict {
    @media (min-width: #{$hd-width}) and (max-width: #{$hd-width - 1px}) {
        @content;
    }
}

@mixin hd {
    @media (min-width: #{$hd-width}) {
        @content;
    }
}
