header {
    .navbar {
        //background: rgba(192,168,127,0.8);
        margin-bottom: 0;
        padding-bottom: 20px;

        &.fixed {
            position: fixed;
            box-shadow: 0 3px 7px 0 rgba(50, 50, 50, 0.12);
            background: rgba(255, 255, 255, 0.9);
            top: 0;
            left: 0;
            right: 0;

            .container .navbar-header .navbar-brand {
                @include desktop {
                    max-height: 80px;
                }
            }
        }

        .container {
            display: flex;
            flex-grow: 0;
            flex-wrap: wrap;

            .navbar-header {
                display: flex;
                justify-content: space-between;
                flex: 1 100%;


                @include tablet {
                    flex: 0;
                }

                .navbar-brand {
                    float: none;
                    height: auto;
                    max-height: 80px;
                    padding: 0;
                    display: flex;
                    transition: all 1s;

                    @include desktop {
                        max-height: 120px;

                    }

                    img {
                        object-fit: contain;
                        object-position: left center;
                    }
                }

                .navbar-toggle {
                    position: relative;
                    float: none;
                    padding: 9px 10px;
                    margin: 0;
                    background-color: $blue;
                    border: 1px solid transparent;
                    border-radius: 4px;
                    align-self: center;

                    .icon-bar {
                        background-color: #ffffff;
                    }
                }

            }

            .navbar-content {
                flex: 1;
                display: flex;
                flex-direction: column;

                a {
                    color: $blue;
                    font-weight: bold;
                }

                #main-menu, #infobar {
                    align-items: center;
                    //flex: 1;
                }

                #infobar {
                    display: none;
                    padding-top: 10px;
                    @include tablet {
                        display: flex;
                    }

                    & > div {
                        display: flex;
                        flex: 1;

                        @include tablet {
                            padding-right: 20px;
                            justify-content: flex-end;
                        }
                    }
                }

                #main-menu {
                    & > ul {
                        float: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        @include tablet {
                            flex-direction: row;
                        }

                        & > li {
                            padding: 10px 15px;

                            &:hover {
                                //background: $orange-main;
                            }

                            a {
                                padding: 2px 0;
                                white-space: nowrap;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                    background-color: $orange-main;
                                    visibility: hidden;
                                    -webkit-transform: scaleX(0);
                                    transform: scaleX(0);
                                    -webkit-transition: all 0.3s ease-in-out 0s;
                                    transition: all 0.3s ease-in-out 0s;
                                }

                                &:hover {
                                    background: none;

                                    &:before {
                                        visibility: visible;
                                        -webkit-transform: scaleX(1);
                                        transform: scaleX(1);
                                    }
                                }
                            }

                            & > ul {
                                position: absolute;
                                background-color: #fff;
                                border: 1px solid $blue;
                                padding: 5px 20px;
                                margin-top: 2px;
                                display: none;
                                float: none;

                                li {
                                    padding: 5px 0;
                                    float: none;
                                    display: block;

                                    a {
                                        font-weight: normal;
                                    }

                                    &.has-children {
                                        & > a {
                                            font-weight: bold;
                                        }
                                    }

                                    ul {
                                        padding-left: 20px;

                                        li {
                                            a {
                                                font-weight: normal;
                                            }
                                        }
                                    }
                                }
                            }

                            &:hover {
                                & > ul {
                                    display: block;
                                }
                            }

                            ul {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
