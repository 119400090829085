#pagetitle {
    text-align: center;
    color: $blue;
    margin-bottom: 40px;
}
#warranty-form {

    #progress-bar {
        //flex: 1 100%;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            border: 2px solid $orange-main;
            border-radius: 40px;
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
            justify-content: space-evenly;
            overflow: hidden;
            @include tablet {
                flex-basis: 50%;
            }

            li {
                margin: 2px 0;
                padding: 10px 20px;
                width: 33%;

                &:first-child {
                    border-radius: 40px 0  0 40px;
                    margin-left: 2px;
                }

                &:last-child {
                    border-radius: 0 40px 40px 0;
                    margin-right: 2px;
                }

                &.done {
                    background: lighten($orange-main, 10%);
                    color: #ffffff;
                }

                &.active {
                    background: $orange-main;
                    color: #ffffff;
                }
            }
        }
    }
    #warranty-container, #login-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .form-step {
            border: 1px solid $blue;
            box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
            display: flex;
            @include tablet {
                flex-basis: 50%;
            }

            .left-side {
                display: none;
                padding: 10px 20px;
                background: $blue;
                color: #ffffff;
                max-width: 50%;
                flex-wrap: wrap;
                @include tablet {
                    display: initial;
                }
            }

            form {
                flex: 1;
                padding: 20px;

                .form-group {
                    margin-bottom: 2em;

                    label {
                        display: block;
                    }

                    input.form-control {
                        font-size: 1.5em;
                        border: solid $blue;
                        border-width: 0 0 2px;
                        padding: 2px 5px;

                        &:focus {
                            background: $lightblue;
                            color: #ffffff;
                        }
                    }

                    &.error {
                        label {
                            color: red;
                        }

                        input.form-control {
                            border-color: red;
                        }
                    }
                }

                .submit-container {
                    display: flex;
                    justify-content: flex-end;

                }
            }
        }

        .list-leave-active,
        .list-enter-active {
            transition: .8s;
            opacity: 1;
        }

        .list-enter-to {
            //opacity: 0;
        }

        .list-enter {
            transform: translate(100%, 0);
            opacity: 0;
        }

        .list-leave-to {
            transform: translate(-100%, 0);
            opacity: 0;
        }

        #step4 {
            input[type='checkbox'], label {
                display: inline;
            }
        }

        #step5 {
            padding: 20px 40px;
        }
    }
}