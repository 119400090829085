#content-html {
    min-height: 100vh;
}

.frontend-form {
    legend {
        border-color: $orange-main;
    }

    .form-group {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;

            label {
                flex-basis: 30%;
                text-align: right;
                padding-right: 20px;
            }
            input, select, textarea {
                flex: 1;
            }
        }
    }
}

.left-sibebar {
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 0 0 40px 0;
    margin-bottom: 10px;
    background: #ffffff;

    ul {
        width: 100%;
        margin-top: 4px;
        padding: 0;

        @include tablet {
            max-width: 290px;
        }

        li {
            width: 100%;
            position: relative;
            display: block;
            float: none;

            a:hover,
            a:focus {
                text-decoration: none;
                color: #fff;
                background-color: #df4c44;
            }
        }

        li.active > a, li.active > a:hover, li.active > a:focus {
            color: #fff;
            background-color: #df4c44;
        }
    }

    a {
        position: relative;
        display: block;
        padding: 12px 28px;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 0;
        color: inherit;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        transition: all 300ms;
    }

    .user-avatar {
        margin: 20px 0;
        width: 100%;
    }

    .user-avatar-iconbox {
        width: 100%;
        padding: 20px;
    }

    .user-avatar-icon {
        height: 180px;
        margin: auto;
        background: url(/images/user-icon.svg) center center no-repeat;
    }

    .user-avatar-details-box {
        text-align: center;
        color: #404040;

        .avatar-user-name {
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 1px;
        }
    }

    @include large {
        max-width: 20%;
    }
}

#scroll-up {
    position: fixed;
    bottom: 30px;
    right: 20px;
    text-indent: -9999px;
    width: 38px;
    height: 38px;
    background-image: url(/images/arrow.png);
    display: none;
    z-index: 1000;
}

#popup-window {
    display: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.5);
}

.admin-icons {
    list-style: none;

    li {
        display: inline-block;
    }
}

.admin-table {
    margin-top: 2em;

    td, th {
        border: solid;
        border-width: 1px 0;
        padding: 5px 10px;
    }

    th {
        background: #e1e1e1;
    }

    tr.row0 td {
        background: #eceff0;
    }

    tr.row1 td {
        background: #ffffff;
    }

    tr:hover td {
        background: #b6ff6c;
    }

    .glyphicon.inactive {
        color: #bbbbbb;
    }

    form {
        display: inline;
    }
}

#warranty-admin {
    #warranty-tablist {
        li {
            a {
                position: relative;
                text-decoration: none;
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $orange-main;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }

                &:hover {
                    text-decoration: none;
                    &:before {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    #tabs {
        margin-bottom: 20px;

        & > ul {
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
            flex-direction: column;

            @include tablet {
                flex-direction: row;
            }

            li {
                padding: 5px 10px;
                border-left: 1px solid $orange-main;
                border-right: 1px solid $orange-main;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;

                @include tablet {
                    border-left: none;
                    &:last-child {
                        border-right: none;
                    }
                }

                &.active {
                    background: $orange-main;

                    a {
                        color: white;
                    }
                }

                a {
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }
}

h3.underlined {
    color: $orange-main;
    border-bottom: 1px solid $orange-main;
}

.master-slider-parent {
    padding: 30px 0;

    .ms-slide-info {
        position: absolute !important;
        bottom: 0;

        .ms-info span {
            display: inline-block;
            padding: 5px 10px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #ffffff;
        }
    }
}

.colored {
    background: rgb(240, 246, 253);
}
.main-padding {
    padding: 30px 0;
}

.latestnews-box {

    .latestnews-img {
        text-align: center;
        a {
            display: inline-block;
            overflow: hidden;

            img {
                -webkit-border-radius: 250px;
                -moz-border-radius: 250px;
                border-radius: 250px;
            }
        }
    }
}

#login {
    @extend #warranty-form;
}
