#complaint {
    border: rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px 0px;
    padding: 10px 20px;

    display: flex;
    flex-direction: column;

    h1 {
        text-align: center;
        margin-bottom: 1em;
    }

    dl {
        margin: 0;
        line-height: 16px;
        vertical-align: center;
        dt {
            float: left;
            clear: left;
            vertical-align: center;
        }

        dd {
            float: left;
            margin-left: 10px;
            font-size: 16px;
            vertical-align: center;
            @media print {
                font-size: 12px;
            }
        }
    }

    #complaint-logo-header {
        display: flex;
        align-items: center;
        img {
            max-height: 80px;
        }

        h1 {
            margin: 0;
            flex: 1;
        }
    }

    #complaint-header {
        display: flex;
        justify-content: space-between;
        border: solid $grey;
        border-width: 1px 0;

    }

    @media print {
        legend {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    #details, #complaint-details {
        display: flex;
        flex: 1;
        justify-content: center;

        & > div {
            //flex-basis: 33%;
            flex: 1;
            border: solid $grey;
            border-width: 0 1px 1px 0;
            padding: 10px 20px;

            &:last-child {
                border-width: 0 0 1px 0;
            }
        }
        dt {
            width: 160px;
            @media print {
                width: 120px;
            }

        }

        #address-details {
            dt {
                width: 100px;
                @media print {
                    width: 80px;
                }
            }
        }
        //dd {
        //    margin-bottom: 1em;
        //}
        //@media print {
        //    dd {
        //        margin-bottom: 0.2em;
        //    }
        //}

    }

    #complaint-images {
        padding-top: 10px;
    }

    #note {
        display: flex;
        justify-content: center;
    }

    #solutions {
        margin-top: 1em;
        form, .cols {
            display: flex;
            flex-wrap: wrap;

            .col-33 {
                flex: 1 1 33%;
                padding: 0 15px;
            }

            .col-66 {
                flex: 1 1 66%;
                padding: 0 15px;
            }
            .col-100 {
                flex: 1 1 100%;
            }

            .align-bottom {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }

    #signs {
        display: flex;
        .column {
            flex: 1;
            padding: 0 15px;
        }
    }

    @media print {
        font-size: 0.8em;
        input {
            //font-size: 0.8em;
        }

        a[href]:after {
            content: none !important;
        }

        #print-helper {
            display: flex;
            flex-direction: column-reverse;
        }



        #complaint-details {
        /*    page-break-after: always;*/
        }

    

    }
}

        #complaint-images {
	    display:flex;
	    flex-wrap:wrap;
	    position:relative;
            //display: none;

	    a {
	        flex: 1 1 33%;
	        flex-wrap: wrap;
	        align-items: center;
	        justify-content: center;
	        display: flex;
		padding: 1em;
	    }


            img {
                //width: 30%;
		display: block;
		max-width: 100%;
            }
	}